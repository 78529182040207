<template>
  <div class="body-content">
    <div class="index-container">
      <div class="action-buttons">
        <el-tooltip content="收藏试卷" placement="right">
          <el-button class="icon-button" :icon="isCollected ? 'el-icon-star-on' : 'el-icon-star-off'"
            :class="{ 'collected': isCollected }" @click="toggleCollect"></el-button>
        </el-tooltip>
        <el-tooltip content="随手记" placement="right">
          <el-button class="icon-button" icon="el-icon-edit-outline"></el-button>
        </el-tooltip>
        <el-tooltip content="历史答案" placement="right">
          <el-button class="icon-button" icon="el-icon-time"></el-button>
        </el-tooltip>
        <el-tooltip content="评论" placement="right">
          <el-button class="icon-button" icon="el-icon-chat-line-round"></el-button>
        </el-tooltip>
        <el-tooltip content="分享" placement="right">
          <el-button class="icon-button" icon="el-icon-share"></el-button>
        </el-tooltip>
      </div>
      <div class="question-section">
        <div class="paper-header">
          <h2 class="paper-text">{{ paperTitle }}</h2>
          <el-button type="primary" size="small" @click="toggleCompareMode" class="compare-btn">
            {{ isCompareMode ? '退出对比' : '多题对比' }}
          </el-button>
        </div>
        <div class="question-tabs">
          <button v-for="(question, index) in questions" :key="index" class="tab-button"
            :class="{ active: currentQuestionIndex === question.questionIndex }" @click="setCurrentQuestion(question)">
            第{{ question.questionIndex }}题
          </button>
        </div>
        <div class="ql-editor" style="height: auto;padding: 0;">
          <div v-html="currentQuestion?.content" class="content-area"></div>
        </div>

        <div class="answer-tabs">
          <button v-for="(answer, index) in answerTabs" :key="index" class="tab-button"
            :class="{ active: currentAnswerIndex === index }" @click="setCurrentAnswer(index)">
            <img v-if="answer.icon" :src="answer.icon" alt="icon" class="answer-icon" />
            <span v-else>{{ answer.label }}</span>
          </button>
        </div>
        <div class="ql-editor" style="height: auto;padding: 0;">
          <div v-if="currentAnswer.source !== '网友补充'" v-html="currentAnswer?.content" class="content-area"></div>
          <div v-else class="content-area">
            <div v-for="(answer, index) in userAddAnswers" :key="index">
              <div class="header">
                <div class="left">{{ "补充" + (index + 1) + "：" + answer.source }}</div>
                <div class="right"></div>
              </div>
              <div v-html="answer.content"></div>
            </div>
          </div>
        </div>
        <div class="answer-actions">
          Tips:先输入答案，点击
          <button @click="compareOverlap" class="action-button">对比重合度</button>
          根据对比情况，整理好答案后，再点击
          <button @click="uploadToAnswerBank" class="action-button">上传至答案库</button>
        </div>
        <div class="answer-box">
          <quill-editor v-model="currentQuestion.answer" :options="editorOption" @change="onEditorChange($event)" />
          <div class="answer-info">
            <span>此题已上传过 {{ currentQuestion.answerCount }} 次答案，</span>
            <a href="javascript:" @click="reviewAnswers" class="review-link">点此回顾</a>
          </div>
        </div>
      </div>
      <div class="materials-section" v-if="!isCompareMode">
        <div class="materials-tabs-container">
          <div class="materials-tabs">
            <button v-for="(material, index) in materials" :key="index" class="tab-button"
              :class="{ active: currentMaterialIndex === material.materialIndex }"
              @click="setCurrentMaterial(material)">
              材料{{ material.materialIndex }}
            </button>
          </div>
        </div>
        <div class="materials-content">
          <div class="ql-editor" style="height: auto; padding: 0;">
            <div v-html="currentMaterial?.content" class="content-area"></div>
          </div>
        </div>
      </div>
      <div class="compare-section" v-else>
        <div class="compare-container">
          <el-cascader v-model="selectedPaper" :options="regionPaperOptions" :props="{
            expandTrigger: 'hover',
            label: 'label',
            value: 'value',
            children: 'children',
            lazy: true,
            lazyLoad: loadPaperOptions
          }" @change="handlePaperChange" placeholder="请选择地区和试卷" class="paper-select" filterable></el-cascader>

          <div v-if="comparePaper" class="compare-paper-content">
            <div class="question-tabs">
              <button v-for="(question, index) in comparePaper.questions" :key="index" class="tab-button"
                :class="{ active: comparePaper.currentQuestionIndex === index + 1 }"
                @click="setCompareQuestionIndex(index + 1)">
                第{{ index + 1 }}题
              </button>
            </div>
            <div class="content-area">
              <div class="ql-editor" style="height: auto;padding: 0;">
                <div v-if="currentCompareQuestion" v-html="currentCompareQuestion.content"></div>
              </div>
            </div>

            <div class="answer-tabs">
              <button v-for="(answer, index) in comparePaper.answerTabs" :key="index" class="tab-button"
                :class="{ active: comparePaper.currentAnswerIndex === index }" @click="setCompareAnswerIndex(index)">
                <img v-if="answer.icon" :src="answer.icon" alt="icon" class="answer-icon" />
                <span v-else>{{ answer.label }}</span>
              </button>
            </div>

            <div class="ql-editor" style="height: auto;padding: 0;">
              <div v-if="currentCompareAnswer.source !== '网友补充'" v-html="currentCompareAnswer?.content"
                class="content-area"></div>
              <div v-else class="content-area">
                <div v-for="(answer, index) in compareUserAddAnswers" :key="index">
                  <div class="header">
                    <div class="left">{{ "补充" + (index + 1) + "：" + answer.source }}</div>
                    <!-- <div class="right"></div> -->
                  </div>
                  <div v-html="answer.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { getQuestionList, getMaterialList, getAnswerList, getUserAddAnswerList, getPaperList } from '@/api';
import discussCategory from '@/components/business/discuss/discussCategory.vue';


export default {
  name: 'ChineseLearningApp',
  components: {
    quillEditor,
    'discuss-category': discussCategory
  },
  data() {
    return {
      paperTitle: '',
      currentQuestionIndex: 1,
      currentMaterialIndex: 1,
      currentAnswerIndex: 0,
      questions: [],
      materials: [],
      answers: [],
      userAddAnswers: [],
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'color': [] }],
            [{ 'align': [] }],
            ['clean']
          ]
        },
        placeholder: '请输入您的答案'
      },
      answerTabs: [
        { icon: require('@/assets/images/fenbi.png'), source: '粉笔' },
        { icon: require('@/assets/images/huatu.png'), source: '华图' },
        { icon: require('@/assets/images/zhanzhang.png'), source: '站长' },
        { icon: require('@/assets/images/zhonggong.png'), source: '中公' },
        { label: '网友补充', source: '网友补充' }
      ],
      isCollected: false,
      pageParams: {
        pageNum: 1,
        pageSize: 1000,
        paperId: '',
        questionId: '',
      },
      isCompareMode: false,
      selectedPaper: [],
      comparePaper: null,
      regionPaperOptions: []
    }
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex - 1];
    },
    currentMaterial() {
      return this.materials[this.currentMaterialIndex - 1];
    },
    currentAnswer() {
      if (!this.answers || this.answers.length === 0) {
        return { content: '暂无答案数据' };
      }
      const currentTab = this.answerTabs[this.currentAnswerIndex];
      const matchedAnswer = this.answers.find(answer => answer.source === currentTab.source);
      if (matchedAnswer) {
        return { content: matchedAnswer.content || '暂无答案数据', source: currentTab.source };
      } else {
        return { content: '暂无答案数据', source: currentTab.source };
      }
    },
    areaList() {
      return this.$store.getters["category/getState"].areaCategoryList;
    },
    currentCompareAnswer() {
      if (!this.comparePaper || !this.comparePaper.answers) {
        return { content: '暂无答案数据' };
      }
      const currentTab = this.comparePaper.answerTabs[this.comparePaper.currentAnswerIndex];
      if (currentTab.source === '网友补充') {
        return { content: '', source: '网友补充' };
      }
      const matchedAnswer = this.comparePaper.answers.find(answer => answer.source === currentTab.source);
      return matchedAnswer || { content: '暂无答案数据', source: currentTab.source };
    },
    compareUserAddAnswers() {
      if (!this.comparePaper || !this.comparePaper.userAddAnswers) return [];
      return this.comparePaper.userAddAnswers;
    },
    currentCompareQuestion() {
      if (!this.comparePaper || !this.comparePaper.questions) {
        return null;
      }
      return this.comparePaper.questions[this.comparePaper.currentQuestionIndex - 1];
    }
  },
  created() {
    this.paperTitle = this.$route.query.title || '未找到题信息';
    const paperId = this.$route.params.id;
    if (paperId) {
      this.getQuestionList(paperId);
      this.getMaterialList(paperId);
    }
    if (this.areaList.length === 0) {
      this.$store.dispatch('category/GetAreaList').then(() => {
        this.initRegionOptions();
      });
    } else {
      this.initRegionOptions();
    }
  },
  methods: {
    setCurrentQuestion(question) {
      this.currentQuestionIndex = question.questionIndex;
      this.currentAnswerIndex = 0;
      this.getAnswerList(question.questionId);
      this.getUserAddAnswerList(question.questionId);
    },

    onEditorChange({ quill, html, text }) {
      this.currentQuestion.answer = html;
    },
    reviewAnswers() {
      console.log('跳转到回顾页面');
    },
    setCurrentMaterial(material) {
      this.currentMaterialIndex = material.materialIndex;
    },
    setCurrentAnswer(index) {
      this.currentAnswerIndex = index;
    },
    compareOverlap() {
      // 实现对比重合度的逻辑
      console.log('对比重合度');
    },
    uploadToAnswerBank() {
      // 实现上传至答案库的逻辑
      console.log('上传至答案库');
    },
    toggleCollect() {
      this.isCollected = !this.isCollected;
      if (this.isCollected) {
        this.$store.dispatch('user/AddToCollection', {
          id: this.$route.params.id,
          text: this.paperTitle
        });
        this.$message.success('收藏成功');
      } else {
        // 如果需要，这里可以添加取消收藏的逻辑
        this.$message.info('取消收藏');
      }
    },


    getQuestionList(paperId) {
      const params = {
        paperId: paperId,
        pageSize: 1000,
        pageNum: 1
      };
      getQuestionList(params).then(response => {
        this.questions = response.rows || [];
        if (this.questions.length > 0 && this.questions[0].questionId) {
          this.getAnswerList(this.questions[0].questionId);
          this.getUserAddAnswerList(this.questions[0].questionId);
        }
      }).catch(error => {
        this.$message.error(error);
        this.questions = [];
      });
    },

    getAnswerList(questionId) {
      const params = {
        questionId: questionId,
        pageSize: 1000,
        pageNum: 1
      };
      getAnswerList(params).then(response => {
        this.answers = response.rows || [];
        // 默认显示第一个有内容的答案
        const firstAnswerIndex = this.answerTabs.findIndex(tab =>
          this.answers.some(answer => answer.source === tab.source)
        );
        if (firstAnswerIndex !== -1) {
          this.currentAnswerIndex = firstAnswerIndex;
        }
      }).catch(error => {
        this.$message.error(error);
        this.answers = [];
      });
    },

    getUserAddAnswerList(questionId) {
      const params = {
        questionId: questionId,
        pageSize: 1000,
        pageNum: 1,
        status: 1
      };
      getUserAddAnswerList(params).then(response => {
        this.userAddAnswers = response.rows || [];
      }).catch(error => {
        this.$message.error(error);
        this.userAddAnswers = [];
      });
    },

    getMaterialList(paperId) {
      const params = {
        paperId: paperId,
        pageSize: 1000,
        pageNum: 1
      };
      getMaterialList(params).then(response => {
        this.materials = response.rows || [];
      }).catch(error => {
        this.$message.error(error);
        this.materials = [];
      });
    },
    toggleCompareMode() {
      this.isCompareMode = !this.isCompareMode;
      if (!this.isCompareMode) {
        this.selectedPaper = [];
        this.comparePaper = null;
      }
    },
    handlePaperChange(value) {
      if (value && value.length === 2) {
        const region = this.regionPaperOptions.find(item => item.value === value[0]);

        Promise.all([
          getQuestionList({
            paperId: value[1],
            pageSize: 1000,
            pageNum: 1
          })
        ]).then(([questionsResponse]) => {
          this.comparePaper = {
            region: region.label,
            paper: value[1],
            value: value[1],
            questions: questionsResponse.rows || [],
            answers: [],
            userAddAnswers: [],
            currentQuestionIndex: 1,
            currentAnswerIndex: 0,
            answerTabs: [
              { icon: require('@/assets/images/fenbi.png'), source: '粉笔' },
              { icon: require('@/assets/images/huatu.png'), source: '华图' },
              { icon: require('@/assets/images/zhanzhang.png'), source: '站长' },
              { icon: require('@/assets/images/zhonggong.png'), source: '中公' },
              { label: '网友补充', source: '网友补充' }
            ]
          };

          if (questionsResponse.rows && questionsResponse.rows.length > 0) {
            const firstQuestion = questionsResponse.rows[0];
            getAnswerList({
              questionId: firstQuestion.questionId,
              pageSize: 1000,
              pageNum: 1
            }).then(response => {
              this.comparePaper.answers = response.rows || [];
            });

            getUserAddAnswerList({
              questionId: firstQuestion.questionId,
              pageSize: 1000,
              pageNum: 1,
              status: 1
            }).then(response => {
              this.comparePaper.userAddAnswers = response.rows || [];
            });
          }
        }).catch(error => {
          this.$message.error(error);
        });
      }
    },
    setCompareQuestionIndex(index) {
      if (this.comparePaper) {
        this.comparePaper.currentQuestionIndex = index;
        const currentQuestion = this.comparePaper.questions[index - 1];
        if (currentQuestion) {
          getAnswerList({
            questionId: currentQuestion.questionId,
            pageSize: 1000,
            pageNum: 1
          }).then(response => {
            this.comparePaper.answers = response.rows || [];
          }).catch(error => {
            this.$message.error(error);
            this.comparePaper.answers = [];
          });

          getUserAddAnswerList({
            questionId: currentQuestion.questionId,
            pageSize: 1000,
            pageNum: 1,
            status: 1
          }).then(response => {
            this.comparePaper.userAddAnswers = response.rows || [];
          }).catch(error => {
            this.$message.error(error);
            this.comparePaper.userAddAnswers = [];
          });
        }
      }
    },
    setCompareAnswerIndex(index) {
      if (this.comparePaper) {
        this.comparePaper.currentAnswerIndex = index;
      }
    },
    initRegionOptions() {
      this.regionPaperOptions = this.areaList.map(area => ({
        label: area.content,
        value: area.areaId,
        leaf: false
      }));
    },
    loadPaperOptions(node, resolve) {
      if (node.level === 0) {
        resolve(this.regionPaperOptions);
        return;
      }

      if (node.level === 1) {
        getPaperList({
          pageSize: 1000,
          pageNum: 1,
          areaId: node.value
        }).then(response => {
          const papers = (response.rows || []).map(paper => ({
            label: paper.title,
            value: paper.paperId,
            leaf: true
          }));
          resolve(papers);
        }).catch(() => {
          resolve([]);
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.body-content {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .index-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .action-buttons {
      width: 5%;
      margin-right: 12px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .icon-button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        margin-bottom: 15px;
        background-color: white;
        border: 1px solid #dce6e3b0;
        display: block;
        text-align: center;
        margin-left: 0px !important;
        transition: all 0.3s;

        &:hover {
          background-color: #f2f6fc8a;
          transform: scale(1.1);
        }

        & i {
          font-size: 20px;
          color: #606266;
        }
      }
    }
  }
}

.answer-actions {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;

  .action-button {
    padding: 5px 10px;
    margin: 0 10px;
    background-color: #46B4B1;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #378F8C;
    }
  }
}

.question-section {
  width: 50%;
  padding-right: 20px;
}

.materials-section {
  width: 45%;
  padding-left: 20px;
  border-left: 1px solid #e0e0e0;
  min-width: 0;
  min-height: 80vh;
  overflow-y: auto;
}

.question-tabs,
.materials-tabs,
.answer-tabs {
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
}

.paper-text {
  font-size: 18px;
  text-align: center;
  color: #333;
}

.materials-tabs-container {
  overflow-x: auto;
  white-space: normal;
}

.materials-tabs-container::-webkit-scrollbar {
  display: none;
}

.materials-tabs .tab-button:nth-child(9n) {
  margin-right: 0;
}

.tab-button {
  margin: 0 15px 0 0;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 5px 15px;
  transition: all 0.3s;
  position: relative;
}

.tab-button:hover {
  color: #46B4B1;
}


.tab-button.active {
  color: #46B4B1;
}

.tab-button.active::after {
  content: '';
  width: 50%;
  height: 8px;
  background: #46B4B1;
  transform: scaleY(.5);
  position: absolute;
  bottom: -4px;
  right: 0;
  align-items: center;
  left: 25%;
}

.answer-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.answer-box {
  margin-top: 12px;
  position: relative;

  .answer-info {
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 10px;
    font-size: 12px;
    color: #666;

    .review-link {
      text-decoration: underline;
      color: #46B4B1;
      cursor: pointer;
    }

    &:hover {
      color: #378F8C;
    }
  }
}



.quill-editor {
  /* height: 200px; */
  font-size: 14px;

  .ql-container.ql-snow {
    min-height: 150px;
    border: none;
    background-color: #f9f9f9;
    border-radius: 10px;
  }

  .ql-toolbar.ql-snow {
    border: none !important;

  }
}



.content-area {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  overflow-x: auto;

  .header .left {
    color: #46B4B1;
  }

}

.paper-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  position: relative;

  .compare-btn {
    padding: 0 15px;
    position: absolute;
    right: 0;
    background-color: #d7b148;
    border: none;
    height: 24px;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #c1a020;
    }
  }
}

.compare-section {
  flex: 0 0 45%;
  padding-left: 20px;
  border-left: 1px solid #e0e0e0;
  min-width: 0;
  min-height: 80vh;
  overflow-y: auto;

  .paper-select {
    width: 100%;
    line-height: 25px;
    background-color: #fff;
    border-radius: 5px !important;

    :deep(.el-input__inner) {
      height: 25px !important;
      line-height: 25px !important;
      margin-top: 0 !important;
      background-color: #fff !important;
      font-size: 14px !important;
      border-radius: 5px !important;
    }
  }
}

:deep(.el-tabs__item) {
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
}

:deep(.el-tabs--card > .el-tabs__header .el-tabs__item.is-active) {
  color: #46B4B1;
}

:deep(.el-tabs--card > .el-tabs__header) {
  border-bottom: none;
}

:deep(.el-cascader) {
  width: 100%;
}
</style>
