<template>
    <div>
        <div class="wrapper">
            <el-form class="login-content" ref="loginForm" :model="loginForm" :rules="loginRules">
                <div class="login-title">
                    <img src="@/assets/images/logo.png" alt="">
                    欢迎使用囊中对比
                </div>
                <el-form-item class="account" prop="account">
                    <el-input prefix-icon="el-icon-message" v-model="loginForm.account" placeholder="请输入邮箱"
                        class="account-input"></el-input>
                </el-form-item>
                <el-form-item class="password" prop="password">
                    <el-input prefix-icon="el-icon-lock" v-model="loginForm.password" type="password"
                        placeholder="请输入密码" class="password-input"></el-input>
                </el-form-item>
                <div class="login-options">
                    <a href="javascript:" @click="register" class="register-link">注册新账号</a>
                    <a href="javascript:" @click="forgetPassword" class="forget-password-link">忘记密码？</a>
                </div>
                <div class="operate">
                    <el-button type="primary" :loading="loading" @click="login">登录</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    name: "AccountLoginPage",
    data() {
        return {
            loginForm: {
                account: '',
                password: '',
                type: '0'
            },
            loginRules: {
                account: [{ required: true, message: '请输入邮箱', trigger: 'blur' },
                { type: 'email', message: '请输入正确的邮箱格式', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' },
                { min: 6, message: '密码长度至少为6位', trigger: 'blur' }],
            },
            redirect: undefined,
            loading: false,
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true
        }
    },

    methods: {
        async login() {
            try {
                const valid = await this.$refs.loginForm.validate();
                if (!valid) {
                    this.$message.error('请正确填写登录信息');
                    return;
                }
                this.loading = true;
                await this.$store.dispatch("user/Login", this.loginForm);
                await this.$router.push(this.redirect || "/community/home");
            } catch (error) {
                console.error('登录失败:', error);
                this.$message.error(error || '登录失败，请重试');
            } finally {
                this.loading = false;
            }
        },
        forgetPassword() {
            this.$router.push("/login/forget");
        },
        register() {
            this.$router.push("/login/register");
        }
    }
}

</script>
<style lang="scss" scoped>
.wrapper {
    background-image: url(~@/assets/images/login-bgc.svg);
    background-size: 100%;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    .login-content {
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(129, 129, 129, 0.25);
        max-width: 500px;
        width: 100%;
        padding: 60px 0px;
        background-color: $background-color;
        display: flex;
        align-items: center;
        flex-direction: column;

        // align-items : center;
        .login-title {
            font-size: $font-size-Level4;
            line-height: 48px;

            img {
                width: 36px;
            }
        }

        .account,
        .password {
            input:focus {
                border: 1px solid $color-primary;
            }

            ;
        }

        .account {
            margin-top: 24px;
            position: relative;
            display: flex;
            align-items: center;
            width: 320px;

            input:focus {
                border: 1px solid $color-primary !important;
            }

            .icon-wrapper {
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
                color: $font-color-fourth;
            }

            .account-input :deep(.el-input__inner) {
                height: 48px !important;
                border-radius: 25px;
                width: 320px;
                padding: 0 24px 0 40px;
                background-color: $background-color-secondary;
                color: $font-color-fourth;

                &:focus {
                    border: 1px solid $color-primary !important;
                }
            }
        }

        .password {
            margin-top: 32px;
            position: relative;
            display: flex;
            align-items: center;
            width: 320px;

            .icon-wrapper {
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
                color: $font-color-fourth;
            }

            .password-input :deep(.el-input__inner) {
                background-color: $background-color-secondary;
                color: $font-color-fourth;
                width: 320px;
                height: 48px !important;
                border-radius: 25px;
                padding: 0 24px 0 40px;

                &:focus {
                    border: 1px solid $color-primary !important;
                }
            }

        }

        .login-options {
            width: 320px;
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                color: $font-color-third;
                text-decoration: none;
                font-size: $font-size-primary;

                &:hover {
                    text-decoration: underline;
                }
            }


        }

        .operate {
            margin-top: 32px;
            display: flex;
            justify-content: center;
            width: 320px;

            button {
                width: 160px;
                padding: 12px 32px;
                border-radius: 25px;
                border: none;
                color: $font-color-secondary;
            }

            button:nth-child(1) {
                background-color: $color-secondary;
            }

            button:nth-child(1):hover {
                background-color: $color-primary;
            }
        }

        .login-aggrement {
            margin-top: 66px;

            a {
                color: $font-color-third;
            }
        }
    }
}
</style>
