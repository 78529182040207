<template>
    <div>
        <div class="wrapper">
            <div class="forget-content">
                <div class="forget-title">
                    <img src="@/assets/images/logo.png" alt="">
                    找回密码
                </div>
                <div class="input-group">
                    <span class="icon-wrapper">
                        <i class="el-icon-message"></i>
                    </span>
                    <input v-model="account" type="email" placeholder="请输入邮箱">
                </div>
                <div class="input-group">
                    <span class="icon-wrapper">
                        <i class="el-icon-key"></i>
                    </span>
                    <input v-model="code" type="text" placeholder="请输入验证码">
                    <button @click="sendVerificationCode" class="send-code-btn"
                        :disabled="isSendingCode || countdown > 0">
                        {{ countdown > 0 ? `${countdown}s后重新发送` : '发送验证码' }}
                    </button>
                </div>
                <div class="input-group">
                    <span class="icon-wrapper">
                        <i class="el-icon-lock"></i>
                    </span>
                    <input v-model="password" type="password" placeholder="请输入新密码">
                </div>

                <div class="operate">
                    <button @click="resetPassword">重置密码</button>
                </div>
                <div class="login-alert">
                    记起密码？<a href="javascript:" @click="goToLogin">立即登录</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {register, resetPassword, sendEmailCode} from '@/api'

export default {
    name: "ForgetPasswordPage",
    data() {
        return {
            account: '',
            code: '',
            password: '',
            isSendingCode: false,
            countdown: 0,
            uuid: null
        }
    },
    methods: {

        sendVerificationCode() {
            if (!this.validateEmail(this.account.trim())) {
              this.$message.error('请输入有效的邮箱地址');
              return;
            }
            if (this.isSendingCode) {
              return;
            }
            this.isSendingCode = true;
            sendEmailCode({
              email: this.account.trim(),
            }).then(response => {
              this.$message.success('验证码已发送，请查收邮箱');
              this.uuid = response.uuid;
              this.isSendingCode = false;
            }).catch(error => {
              this.$message.error(error);
            });
            this.startCountdown();
        },

        startCountdown() {
            this.countdown = 60;
            const timer = setInterval(() => {
                this.countdown--;
                if (this.countdown === 0) {
                    clearInterval(timer);
                }
            }, 1000);
        },

        validateEmail(email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },

        resetPassword() {
            if(!this.validateEmail(this.account.trim())) {
                this.$message.error('请输入有效的邮箱地址');
                return;
            }

            if (!this.password || this.password.length < 6) {
              this.$message.error('密码长度至少为6位');
            }

            if (this.code.trim().length !== 6) {
                this.$message.error('验证码长度应为6位');
                return;
            }

            resetPassword({
                account: this.account.trim(),
                code: this.code.trim(),
                password: this.password,
                uuid: this.uuid
            }).then(response => {
                this.$message.success('密码重置成功，请重新登录');
                this.$router.push('/login/account');
            }).catch(error => {
                this.$message.error(error);
            });
        },

        goToLogin() {
            this.$router.push("/login/account");
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    background-image: url(~@/assets/images/login-bgc.svg);
    background-size: 100%;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    .forget-content {
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(129, 129, 129, 0.25);
        max-width: 500px;
        width: 100%;
        padding: 60px 80px;
        background-color: $background-color;
        display: flex;
        align-items: center;
        flex-direction: column;

        .forget-title {
            font-size: $font-size-Level4;
            line-height: 48px;
            margin-bottom: 30px;

            img {
                width: 36px;
                vertical-align: middle;
                margin-right: 10px;
            }
        }

        .input-group {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;

            .icon-wrapper {
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
                color: $font-color-fourth;
            }

            input {
                background-color: $background-color-secondary;
                color: $font-color-fourth;
                width: 100%;
                height: 48px;
                border-radius: 25px;
                padding: 0 24px 0 40px;
                border: 1px solid transparent;

                &:focus {
                    border: 1px solid $color-primary;
                    outline: none;
                }
            }

            .send-code-btn {
                position: absolute;
                right: 10px;
                padding: 5px 10px;
                background-color: $color-primary;
                color: $font-color-secondary;
                border: none;
                border-radius: 15px;
                cursor: pointer;

                &:hover {
                    background-color: darken($color-primary, 5%);
                }

                &:disabled {
                    background-color: #ccc;
                    cursor: not-allowed;
                }
            }
        }

        .operate {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            width: 100%;

            button {
                width: 160px;
                padding: 14px 32px;
                border-radius: 25px;
                border: none;
                color: $font-color-secondary;
                background-color: $color-primary;
                cursor: pointer;

                &:hover {
                    background-color: darken($color-primary, 5%);
                }
            }
        }

        .login-alert {
            margin-top: 24px;
            text-align: center;

            a {
                color: $font-color-third;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
