var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tool-box"},[_c('div',{staticClass:"learn-classroom"},[_vm._v("申论学习室")]),_c('div',{staticClass:"tool-item-list"},[_c('div',{staticClass:"tool-item",on:{"click":_vm.goToSearch}},[_vm._m(0),_c('span',{staticClass:"desc"},[_vm._v("一键搜题")])]),_c('div',{staticClass:"tool-item",on:{"click":_vm.goToAddAnswer}},[_vm._m(1),_c('span',{staticClass:"desc"},[_vm._v("补充答案")])]),_c('div',{staticClass:"tool-item",on:{"click":_vm.goToClock}},[_vm._m(2),_c('span',{staticClass:"desc"},[_vm._v("专注时钟")])]),_vm._m(3),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/assets/images/search-paper.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/assets/images/add-answer.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/assets/images/focus-time.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tool-item"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/images/online-question.png"),"alt":""}})]),_c('span',{staticClass:"desc"},[_vm._v("在线做题")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tool-item"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/images/do-list.png"),"alt":""}})]),_c('span',{staticClass:"desc"},[_vm._v("我的清单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tool-item"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/images/learn-patern.png"),"alt":""}})]),_c('span',{staticClass:"desc"},[_vm._v("学习搭子")])])
}]

export { render, staticRenderFns }