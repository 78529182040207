<template>
    <ul class="nav-list">
        <li class="nav-item"  :class="{ 'nav-item--active': selectedId === null }" @click="selectedCategory()">
            所有省份
        </li>
        <li class="nav-item" 
            :class="{ 'nav-item--active': selectedId === item.areaId }" 
            v-for="item in areaList"
            :key="item.areaId" 
            :title="item.content" 
            @click="selectedCategory(item)"
        >
            {{ item.content }}
        </li>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            selectedId: 1
        }
    },
    computed: {
      areaList() {
        return this.$store.getters["category/getState"].areaCategoryList;
      }
    },
    created() {
        if(this.areaList.length === 0) {
          this.$store.dispatch('category/GetAreaList')
        }
    },
    methods: {
        selectedCategory(item) {
            if (item) {
                this.selectedId = item.areaId;
            } else {
                this.selectedId = null;
            }
            // 向父组件发送选中的 areaId
            this.$emit('areaChange', this.selectedId || null);
        }
    }
}
</script>

<style lang='scss'>
.nav-list {
    width: 148px;
    min-height: auto;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    position: fixed;
    padding: 12px 8px;
    background-color: $background-color;
    border-radius: 10px;
    transition: all .2s;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    /* 隐藏滚动条但保持滚动功能 */
    &::-webkit-scrollbar {
        width: 0;
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .nav-item {
        position: relative;
        width: 64px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $font-color-primary;
        border-radius: 3px;
        transition: background-color .2s, color .2s;
        cursor: pointer;
        margin-bottom: 4px;

        &:nth-child(1) {
            font-size: $font-size-primary;
            flex-basis: 100%;
            margin: 0 0 4px 0;

            &::after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 1px;
                background: #ddd;
                transform: scaleY(.5);
            }
        }

        &:hover {
            background-color: $background-color-secondary;
            color: $color-primary;
        }
    }
    .nav-item--active,.nav-item--active:hover {
        background-color: $background-color-fourth !important;
        color: $color-primary;
    }
}
</style>
