<template>
    <header class="topbar__wrapper">
        <div class="topbar topbar--show">
            <div class="topbar__main">
                <div class="logo">
                    <router-link to="/community/home">
                        <img src="@/assets/images/logo.png" alt="">
                        <img src="@/assets/images/logo-font.png" alt="">
                    </router-link>
                </div>
                <!-- 导航功能 -->
                <div class="navs">
                    <ul class="navs">
                        <router-link v-for="item in navItems" :key="item.name" :to="item.link" class="nav-item">{{
                            item.name }}</router-link>
                    </ul>
                </div>
                <div class="search">
                    <input type="text" placeholder="搜索 内容/工具/用户" />
                    <img src="@/assets/images/search.svg" alt="">
                </div>
                <div class="notice">
                    <img src="@/assets/images/notice.svg" alt="">
                </div>
                <el-dropdown trigger="hover" @command="handleCommand">
                    <div class="user-info">
                        <div class="user-avatar">
                            <user-avatar :url="userInfo.avatar" :nick-name="userInfo.nickName"
                                :round="true"></user-avatar>
                        </div>
                        <span class="topbar-nickname">{{ formatNickName }}</span>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="profile">
                            <i class="el-icon-user"></i> 个人主页
                        </el-dropdown-item>
                        <el-dropdown-item command="logout">
                            <i class="el-icon-switch-button"></i> 退出登录
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'TopBar',
    props: {
        // 通过 props 传入不同的导航配置
        type: {
            type: String,
            default: 'community',
            validator: value => ['community', 'studyRoom'].includes(value)
        }
    },
    computed: {
        navItems() {
            return this.type === 'community'
                ? [
                    { name: '谈谈备考', link: '/community/home' },
                    { name: '平日闲聊', link: '/community/chat' },
                    { name: '争议题讨论', link: '/community/argue' },
                ]
                : [
                    { name: '囊中社区', link: '/community/home' },
                    { name: '申论学习室', link: '/community/studyRoom' },
                ];
        },
        userInfo() {
            return this.$store.getters['user/getState']
        },
        formatNickName() {
            const name = this.userInfo.nickName || '囊友';
            return name.length > 5 ? name.slice(0, 5) + '...' : name;
        }
    },
    methods: {
        handleCommand(command) {
            switch (command) {
                case 'profile':
                    this.$router.push(`/user/${this.userInfo.userId}`);
                    break;
                case 'logout':
                    this.$store.dispatch('user/LogOut').then(() => {
                        this.$router.push('/login/account');
                    }).catch(error => {
                        this.$message.error('退出失败，请重试');
                    });
                    break;
            }
        }
    }
}
</script>
<style scoped lang="scss">
.topbar__wrapper {
    z-index: 1000;
    position: relative;
    height: 60px;
}

.topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 4px #eee;
    transition: all .2s;

    &.topbar--show {
        transform: translateY(0);
    }

    &.topbar--hidden {
        transform: translateY(-100%);
    }
}

.topbar__main {
    display: flex;
    align-items: center;
    max-width: 1280px;
    padding: 0 40px;
    height: 100%;
    margin: 0 auto;

    .logo {
        margin-right: 20px;
        cursor: pointer;

        img:first-child {
            width: 32px;
            margin-right: 16px;
        }

        img:last-child {
            width: 108px;
            margin-right: 56px;
        }
    }
}

.navs {
    display: flex;
    line-height: 60px;
    font-size: $font-size-Level6;
    color: $font-color-fourth;

    .nav-item {
        padding: 0 20px;
        cursor: pointer;

        &:hover,
        &.router-link-active {
            color: $color-primary;
        }
    }

}

.search {
    margin-left: auto;
    display: flex;
    align-items: center;
    position: relative;

    input {
        width: 260px;
        height: 28px;
        border-radius: 28px;
        border: 1px solid $color-third;
        padding: 0 20px;

        &:focus {
            border: 1px solid $color-primary;
        }
    }

    img {
        position: absolute;
        right: 20px;
        cursor: pointer;
    }
}

.notice {
    margin-left: auto;
    margin-right: 18px;
    cursor: pointer;
}

.user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;

    &:hover {
        background-color: $background-color-secondary;
        border-radius: 4px;
    }

    .user-avatar {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    .topbar-nickname {
        color: $font-color-primary;
        font-size: $font-size-primary;
        max-width: 120px;
        display: inline-block;
        white-space: nowrap;
    }
}

:deep(.el-dropdown-menu__item) {
    display: flex;
    align-items: center;
    padding: 8px 20px;

    i {
        margin-right: 8px;
        font-size: 16px;
    }

    &:hover {
        background-color: $background-color-fourth;
        color: $color-primary;
    }
}
</style>