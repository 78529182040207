<template>
  <div id="app">
    <top-bar 
      v-if="module === 'community' || module === 'studyRoom'"
      :type="module"
    ></top-bar>
    <main v-if="module === 'community' || module === 'studyRoom'" class="main">
      <router-view></router-view>
    </main>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import TopBar from '@/components/common/topBar.vue'

export default {
  name: 'App',
  data() {
    return {
      module: ''
    }
  },
  components: {
    TopBar

  },
  watch: {
    '$route'(to) {
      if (to.path.includes("community")) {
        this.module = "community"
        if(to.path.includes("studyRoom")) {
          this.module = "studyRoom"
        }
      } else {
        this.module = "home"
      }
      console.log(this.module);
    },
  },
  created() {
    const path = this.$route.path;
    if (path.includes("community")) {
      this.module = "community"
      if(to.path.includes("studyRoom")) {
        this.module = "studyRoom"
      }
    } else {
        this.module = "home"
    }
  },
}
</script>

<style lang="scss" scoped>
#app {
  background-color: $background-color-secondary;
  min-height: 100vh;
}

.main {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
}

#nprogress .bar {
  background: $color-primary !important;
}
</style>
