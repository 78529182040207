<template>
  <div class="body-content">
    <discuss-category @areaChange="handleAreaChange"></discuss-category>
    <div class="index-container">
      <div class="index-main">
        <div v-for="(item, index) in paperList" :key="index" class="paper-item" @click="goToDetail(item)">
          <div class="paper-text">
            {{ item.title }}
          </div>
        </div>

        <div class="pagination-container">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="pageParams.pageNum" :page-sizes="[15, 30, 50]" :page-size="pageParams.pageSize"
                         layout="  prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="right-section">
      <tool-box class="tool-box"></tool-box>
    </div>
  </div>
</template>

<script>
import discussCategory from '@/components/business/discuss/discussCategory.vue';
import toolBox from '@/components/business/discuss/toolBox.vue';
import { getPaperList } from '@/api';
export default {
  components: {
    'discuss-category': discussCategory,
    'tool-box': toolBox
  },
  data() {
    return {
      paperList: [],
      total: 0,
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        areaId: 1
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageParams.pageSize = val;
      this.pageParams.pageNum = 1;
      this.getPaperList();
    },

    handleCurrentChange(val) {
      this.pageParams.pageNum = val;
      this.getPaperList();
    },

    handleAreaChange(areaId) {
      this.pageParams.areaId = areaId;
      this.pageParams.pageNum = 1;
      this.getPaperList();
    },

    getPaperList() {
      getPaperList({
        ...this.pageParams,
        orderByColumn: 'create_time',
        isAsc: 'desc'
      }).then(response => {
        this.paperList = response.rows || [];
        this.total = response.total || 0;
      }).catch(error => {
        this.$message.error(error);
        this.paperList = [];
        this.total = 0;
      });
    },

    goToDetail(paper) {
      this.$router.push({
        name: 'PaperDetail',
        params: { id: paper.paperId },
        query: {
          title: paper.title
        }
      });
    }
  },
  created() {
    this.getPaperList();
  }
}
</script>

<style lang="scss" scoped>
.body-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .index-container,
  .right-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .index-container {
    display: flex;
    position: relative;
    left: 148px;

    .index-main {
      width: 720px;
      margin-right: 20px;
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      .paper-item {
        padding: 16px 20px;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        background-color: #fff;
        height: 52px;
        box-sizing: border-box;
        &:hover {
          background-color: rgba($color-primary, 0.05);
          color: $color-primary;
        }
      }

      .paper-text {
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $font-color-primary;
      }

      .pagination-container {
        padding-top: 10px;
        text-align: center;
      }
    }
    margin-left: 12px;
  }
}

.right-section {
  width: 300px;
  height: 620px;
}

</style>
