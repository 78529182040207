<template>
  <div class="artic-list">
      <artic-item v-for="(item, index) in list" :key="index" :item.sync="list[index]"></artic-item>
  </div>
</template>

<script>
export default {
  props: {
    hasDesc: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
.artic-list {
  background-color: $background-color;

  .artic-item--border:not(:last-child){
    border-bottom: 1px solid $color-third;
  }
}
</style>