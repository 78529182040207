<template>
    <div class="tool-box">
        <div class="learn-classroom">申论学习室</div>
        <div class="tool-item-list">
            <div class="tool-item" @click="goToSearch">
                <span>
                    <img src="@/assets/images/search-paper.png" alt="">
                </span>
                <span class="desc">一键搜题</span>
            </div>
            <div class="tool-item" @click="goToAddAnswer">
                <span>
                    <img src="@/assets/images/add-answer.png" alt="">
                </span>
                <span class="desc">补充答案</span>
            </div>
            <div class="tool-item" @click="goToClock">
                <span>
                    <img src="@/assets/images/focus-time.png" alt="">
                </span>

                <span class="desc">专注时钟</span>
            </div>
            <div class="tool-item">
                <span>
                    <img src="@/assets/images/online-question.png" alt="">
                </span>

                <span class="desc">在线做题</span>
            </div>
            <div class="tool-item">
                <span>
                    <img src="@/assets/images/do-list.png" alt="">
                </span>
                <span class="desc">我的清单</span>
            </div>
            <div class="tool-item">
                <span>
                    <img src="@/assets/images/learn-patern.png" alt="">
                </span>

                <span class="desc">学习搭子</span>
            </div>
        </div>

    </div>
</template>
  
<script>
export default {
    props: {
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        goToSearch() {
            this.$router.push('/community/studyRoom/search');
        },
        goToAddAnswer() {
            this.$router.push('/community/studyRoom/addAnswer');
        },
        goToClock() {
            this.$router.push('/clock');
        }
    }
}
</script>
  
<style lang='scss' scoped>
.tool-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-color;
    border-radius: 10px;
    padding: 16px;

    .learn-classroom {
        
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-primary;
        font-size: $font-size-Level6;
        font-weight: 600;
    }

    .tool-item-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .tool-item {
            span {
                img {
                    width: 28px;
                }

            }
            
            .desc {
                color: $font-color-fourth;
                margin-top: 12px;
            }

            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
            cursor: pointer;
        }
    }

}</style>