import request from "@/utils/request"

export function getAccessToken(query) {
    return request({
        url: '/api-wx/cgi-bin/token',
        method: 'get',
        params: query
    });
}

export function createQrCode(query, data) {
    return request({
        url: '/api-wx/wxa/getwxacodeunlimit',
        method: 'post',
        params: query,
        data: data,
        responseType: 'blob'
    });
}

export function register(data) {
    return request({
        url: '/api-nz/nz/user/register',
        headers: {
            isToken: false,
        },
        method: 'post',
        data: data
    })
}

export function sendEmailCode(data) {
    return request({
        url: '/api-nz/nz/sendEmailCode',
        headers: {
            isToken: false,
        },
        method: 'get',
        params: data
    })
}

export function resetPassword(data) {
    return request({
        url: '/api-nz/nz/user/resetPassword',
        headers: {
            isToken: false,
        },
        method: 'put',
        data: data
    })
}

export function login(data) {
    return request({
        url: '/api-nz/nz/login',
        headers: {
            isToken: false,
            repeatSubmit: false
        },
        method: 'post',
        data: data,
    });
}

export function getStatData(data) {
    return request({
        url: '/api-nz/nz/common/getStatData',
        headers: {
            isToken: false,
            repeatSubmit: false
        },
        method: 'get',
        data: data,
    });
}

// 退出方法
export function logout() {
    return request({
        url: '/api-nz/logout',
        method: 'post'
    })
}

export function bindInfo(data) {
    return request({
        url: '/api-nz/nz/bindInfo',
        headers: {
            isToken: false,
        },
        method: 'put',
        data: data,
    });
}

export function updateScanCodeStatus(data) {
    return request({
        url: '/api-nz/nz/user/updateScanCodeStatus',
        headers: {
            isToken: false,
        },
        method: 'post',
        data: data,
    });
}


export function getScanCodeStatus(query) {
    return request({
        url: '/api-nz/nz/user/getScanCodeStatus',
        headers: {
            isToken: false,
        },
        method: 'get',
        params: query,
    });
}

export function getWxServeAccessToken(query) {
    return request({
        url: '/api-nz/wxServe/getAccessToken',
        method: 'get',
        params: query,
    });
}

export function createOrder(data) {
    return request({
        url: '/api-nz/nz/userPayOrder/create',
        method: 'post',
        data: data,
    });
}

export function activePay(data) {
    return request({
        url: '/api-nz/pay/activePay',
        method: 'post',
        data: data,
    });
}

export function appPayConfig(query) {
    return request({
        url: '/api-nz/pay/appPayConfig',
        method: 'get',
        params: query,
    });
}

export function getUserInfo(query) {
    return request({
        url: '/api-nz/nz/getUserInfo',
        method: 'get',
        params: query,
    });
}

export function getAreaList(query) {
    return request({
        url: '/api-nz/nz/area/list',
        method: 'get',
        params: query,
    });
}

export function getPaperList(query) {
    return request({
        url: '/api-nz/nz/paper/list',
        method: 'get',
        params: query,
    });
}

export function getQuestionList(query) {
    return request({
        url: '/api-nz/nz/question/list',
        method: 'get',
        params: query,
    });
}

export function getMaterialList(query) {
    return request({
        url: '/api-nz/nz/material/list',
        method: 'get',
        params: query,
    });
}

export function getAnswerList(query) {
    return request({
        url: '/api-nz/nz/answer/list',
        method: 'get',
        params: query,
    });
}

export function getCollectPaperList(query) {
    return request({
        url: '/api-nz/nz/paper/collectList',
        method: 'get',
        params: query,
    });
}

export function getUserAddAnswerList(query) {
    return request({
        url: '/api-nz/nz/userAddAnswer/list',
        method: 'get',
        params: query,
    });
}

export function getUserCommentList(query) {
    return request({
        url: '/api-nz/nz/userComment/list',
        method: 'get',
        params: query,
    });
}

export function getUserNoteList(query) {
    return request({
        url: '/api-nz/nz/userNote/list',
        method: 'get',
        params: query,
    });
}

export function getTargetActionList(query) {
    return request({
        url: '/api-nz/nz/userAction/getTargetsActionStatus',
        method: 'get',
        params: query,
    });
}

export function doAction(data) {
    return request({
        url: '/api-nz/nz/userAction/userAction',
        method: 'post',
        data: data,
    });
}

export function getNoticeList(query) {
    return request({
        url: '/api-nz/nz/notice/list',
        method: 'get',
        params: query,
    });
}

export function getVersionMealList(query) {
    return request({
        url: '/api-nz/nz/versionMeal/list',
        method: 'get',
        params: query,
    });
}

export function getOcr(query) {
    return request({
        url: '/api-nz/common/ocr',
        method: 'get',
        params: query,
    });
}

export function getArticleList(query) {
    return request({
        url: '/api-nz/nz/article/community/list',
        method: 'get',
        params: query,
    });
}

export function getTagList(query) {
    return request({
        url: '/api-nz/nz/tag/list',
        method: 'get',
        params: query,
    });
}

export function insertUserAnswer(data) {
    return request({
        url: '/api-nz/nz/userAnswer',
        method: 'post',
        data: data,
    });
}

export function insertArticleDraft(data) {
    return request({
        url: '/api-nz/nz/articleDraft',
        method: 'post',
        data: data,
    });
}

export function updateArticleDraft(data) {
    return request({
        url: '/api-nz/nz/articleDraft',
        method: 'put',
        data: data,
    });
}

export function insertArticle(data) {
    return request({
        url: '/api-nz/nz/article',
        method: 'post',
        data: data,
    });
}